/* eslint-disable no-new */
import { AdapterConfig } from '../index.d';

declare let HE_UCL: any;

export default (config: AdapterConfig = {}): void => {
  document.head.appendChild(
    Object.assign(document.createElement('script'), {
      src: `https://sonic-ui.highereducation.com/${config.version}/${config.file}`,
      // src: `../dist/${config.file}`,
      id: 'ucl-loader',
      onload: () => {
        new HE_UCL({
          path: config.path || null,
          presets: config.presets || {},
          scripts: config.scripts || [],
        });
      },
    })
  );
};
