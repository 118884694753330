/* eslint-disable no-underscore-dangle */
import { AdapterConfig } from '../index.d';
import setupConfig from './setupConfig';
import loadScript from './loadScript';

function initUCL(localConfig: AdapterConfig = {}) {
  // For LAKEHOUSE reporting:
  // use the localConfig.identity to set variable window.chsn_sender
  if (localConfig?.identity) {
    window.chsn_sender = localConfig.identity;
  }

  const config = setupConfig(localConfig);

  // disabled?
  if (config?.disable) {
    throw new Error('UCL has been disabled');
  }

  loadScript(config);
}

initUCL(window._UCL);
