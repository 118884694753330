import { AdapterConfig } from '../index.d';
import identities from './identities';

export default (localConfig: AdapterConfig): AdapterConfig => {
  const remoteConfig =
    (localConfig?.identity && identities?.[localConfig.identity]) ||
    (window.location.hostname && identities?.[window.location.hostname]);
  const type = localConfig?.type || remoteConfig?.type || 'publisher';

  return {
    disable: remoteConfig?.disable || false,
    file: type === 'affiliate' ? 'ucl.affiliate.js' : 'ucl.publisher.js',
    path: localConfig?.path || remoteConfig?.path,
    presets: { ...remoteConfig?.presets, ...localConfig?.presets },
    scripts: localConfig?.scripts || remoteConfig?.scripts,
    version: localConfig?.version || remoteConfig?.version || 'latest',
  };
};
